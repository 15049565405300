.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529
}

.table td,.table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6
}

.table tbody+tbody {
    border-top: 2px solid #dee2e6
}

.table-sm td,.table-sm th {
    padding: .3rem
}

.table-bordered,.table-bordered td,.table-bordered th {
    border: 1px solid #dee2e6
}

.table-bordered thead td,.table-bordered thead th {
    border-bottom-width: 2px
}

.table-borderless tbody+tbody,.table-borderless td,.table-borderless th,.table-borderless thead th {
    border: 0
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0,0,0,.05)
}

.table-hover tbody tr:hover {
    color: #212529;
    cursor: pointer;
    background-color: rgba(0,0,0,.075)
}

.table-background,.table-background>td,.table-background>th {
    background-color: #fcfcfc
}

.table-background tbody+tbody,.table-background td,.table-background th,.table-background thead th {
    border-color: #f9f9f9
}

.table-hover .table-background:hover,.table-hover .table-background:hover>td,.table-hover .table-background:hover>th {
    background-color: #efefef
}

.table-text,.table-text>td,.table-text>th {
    background-color: #c1c3c7
}

.table-text tbody+tbody,.table-text td,.table-text th,.table-text thead th {
    border-color: #8c8f98
}

.table-hover .table-text:hover,.table-hover .table-text:hover>td,.table-hover .table-text:hover>th {
    background-color: #b4b6bb
}

.table-inverse,.table-inverse>td,.table-inverse>th {
    background-color: #fff
}

.table-inverse tbody+tbody,.table-inverse td,.table-inverse th,.table-inverse thead th {
    border-color: #fff
}

.table-hover .table-inverse:hover,.table-hover .table-inverse:hover>td,.table-hover .table-inverse:hover>th {
    background-color: #f2f2f2
}

.table-transparent,.table-transparent>td,.table-transparent>th {
    background-color: hsla(0,0%,100%,.72)
}

.table-transparent tbody+tbody,.table-transparent td,.table-transparent th,.table-transparent thead th {
    border-color: hsla(0,0%,100%,.48)
}

.table-hover .table-transparent:hover,.table-hover .table-transparent:hover>td,.table-hover .table-transparent:hover>th {
    background-color: hsla(0,0%,95%,.72)
}

.table-primary,.table-primary>td,.table-primary>th {
    background-color: #ffd0d0
}

.table-primary tbody+tbody,.table-primary td,.table-primary th,.table-primary thead th {
    border-color: #fea8a8
}

.table-hover .table-primary:hover,.table-hover .table-primary:hover>td,.table-hover .table-primary:hover>th {
    background-color: #ffb7b7
}

.table-secondary,.table-secondary>td,.table-secondary>th {
    background-color: #d8d9dc
}

.table-secondary tbody+tbody,.table-secondary td,.table-secondary th,.table-secondary thead th {
    border-color: #b7b9bd
}

.table-hover .table-secondary:hover,.table-hover .table-secondary:hover>td,.table-hover .table-secondary:hover>th {
    background-color: #cbccd0
}

.table-success,.table-success>td,.table-success>th {
    background-color: #d2e8b9
}

.table-success tbody+tbody,.table-success td,.table-success th,.table-success thead th {
    border-color: #abd47e
}

.table-hover .table-success:hover,.table-hover .table-success:hover>td,.table-hover .table-success:hover>th {
    background-color: #c6e2a6
}

.table-info,.table-info>td,.table-info>th {
    background-color: #bee5eb
}

.table-info tbody+tbody,.table-info td,.table-info th,.table-info thead th {
    border-color: #86cfda
}

.table-hover .table-info:hover,.table-hover .table-info:hover>td,.table-hover .table-info:hover>th {
    background-color: #abdde5
}

.table-warning,.table-warning>td,.table-warning>th {
    background-color: #ffeeba
}

.table-warning tbody+tbody,.table-warning td,.table-warning th,.table-warning thead th {
    border-color: #ffdf7e
}

.table-hover .table-warning:hover,.table-hover .table-warning:hover>td,.table-hover .table-warning:hover>th {
    background-color: #ffe8a1
}

.table-danger,.table-danger>td,.table-danger>th {
    background-color: #f5b8b8
}

.table-danger tbody+tbody,.table-danger td,.table-danger th,.table-danger thead th {
    border-color: #ec7a7b
}

.table-hover .table-danger:hover,.table-hover .table-danger:hover>td,.table-hover .table-danger:hover>th {
    background-color: #f2a2a2
}

.table-light,.table-light>td,.table-light>th {
    background-color: #f3f4f5
}

.table-light tbody+tbody,.table-light td,.table-light th,.table-light thead th {
    border-color: #e8eaec
}

.table-hover .table-light:hover,.table-hover .table-light:hover>td,.table-hover .table-light:hover>th {
    background-color: #e5e7e9
}

.table-dark,.table-dark>td,.table-dark>th {
    background-color: #c1c3c7
}

.table-dark tbody+tbody,.table-dark td,.table-dark th,.table-dark thead th {
    border-color: #8c8f98
}

.table-hover .table-dark:hover,.table-hover .table-dark:hover>td,.table-hover .table-dark:hover>th {
    background-color: #b4b6bb
}

.table-visual-focus,.table-visual-focus>td,.table-visual-focus>th {
    background-color: #d6e4fd
}

.table-visual-focus tbody+tbody,.table-visual-focus td,.table-visual-focus th,.table-visual-focus thead th {
    border-color: #b3ccfb
}

.table-hover .table-visual-focus:hover,.table-hover .table-visual-focus:hover>td,.table-hover .table-visual-focus:hover>th {
    background-color: #bed4fc
}

.table-background-focus,.table-background-focus>td,.table-background-focus>th {
    background-color: rgba(251,253,255,.748)
}

.table-background-focus tbody+tbody,.table-background-focus td,.table-background-focus th,.table-background-focus thead th {
    border-color: rgba(246,251,254,.532)
}

.table-hover .table-background-focus:hover,.table-hover .table-background-focus:hover>td,.table-hover .table-background-focus:hover>th {
    background-color: rgba(226,240,255,.748)
}

.table-active,.table-active>td,.table-active>th,.table-hover .table-active:hover,.table-hover .table-active:hover>td,.table-hover .table-active:hover>th {
    background-color: rgba(0,0,0,.075)
}

.table .thead-dark th {
    color: #fff;
    background-color: #343a40;
    border-color: #454d55
}

.table .thead-light th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6
}

.table-dark {
    color: #fff;
    background-color: #343a40
}

.table-dark td,.table-dark th,.table-dark thead th {
    border-color: #454d55
}

.table-dark.table-bordered {
    border: 0
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: hsla(0,0%,100%,.05)
}

.table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: hsla(0,0%,100%,.075)
}

@media (max-width: 575.98px) {
    .table-responsive-sm {
        display:block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }

    .table-responsive-sm>.table-bordered {
        border: 0
    }
}

@media (max-width: 767.98px) {
    .table-responsive-md {
        display:block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }

    .table-responsive-md>.table-bordered {
        border: 0
    }
}

@media (max-width: 991.98px) {
    .table-responsive-lg {
        display:block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }

    .table-responsive-lg>.table-bordered {
        border: 0
    }
}

@media (max-width: 1199.98px) {
    .table-responsive-xl {
        display:block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }

    .table-responsive-xl>.table-bordered {
        border: 0
    }
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
}

.table-responsive>.table-bordered {
    border: 0
}

.table thead th {
    border-bottom: 0;
    border-top: none;
    font-size: .875rem;
    font-weight: "semi";
    text-transform: uppercase;
    color: #747881;
}

.table td {
    vertical-align: middle
}

.table-hover tbody tr {
    min-height: 74px
}

.table-hover tbody tr:hover {
    background-color: #f4f4f4
}

.table--mobile {
    font-weight: "bold";
    display: none
}

@media (max-width: 767.98px) {
    .table--mobile {
        display:inline-flex
    }
}

.table-close {
    position: relative;
    right: 18px;
    width: 18px;
    height: 18px
}

.table-close:hover {
    opacity: 1
}

.table-close:after,.table-close:before {
    position: absolute;
    left: 15px;
    content: " ";
    height: 18px;
    width: 2px;
    background-color: #747881;
    top: 30%
}

.table-close:before {
    transform: rotate(45deg)
}

.table-close:after {
    transform: rotate(-45deg)
}