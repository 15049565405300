// @forward './color';
// @forward './buttons';

@use './buttons';

// Put in whatever color here, the generator will use it as the 500 value of the palette! 
// @import './color';
@use './table';
@use './mixins';
@use './variables';
@use './links';
// /node_modules/@partena/uxlib-design/theme.min.css

// Chips

@use './chips/status_chip';

@use '@partena/uxlib-design/theme.min.css';
@use './partena-overrides';
@import url("https://fonts.googleapis.com/css?family=Inconsolata");


.epg-dialog {
  background-color: #FAFAFF;
  padding: 24px;
  border-radius: 8px;

  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

body {
  @extend .uxlib-app;

  * {
    box-sizing: border-box;
  }
}

.color-pallets {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    // grid-template-columns: repeat(3, calc(100vw/3));
    grid-gap: 1em;
    justify-content: space-between;
    
    padding: 0 1rem;
}

.palette {
  display: grid;
//   writing-mode: vertical-lr;
  grid-template-rows: repeat(14, 1fr);
//   width: 100vw;

  div {
    display: inline-block;
    border: none;
    box-sizing: border-box;
    height: 50px;
    padding: 1vw;
    overflow: hidden;
    animation-duration: 1s;
    animation-delay: .5s;
    // background: $testColor;
    animation-fill-mode: forwards;
    // font-size: 2vw;
    font-size: 24px;
    font-family: Inconsolata, monospace;
    @for $i from 1 to 15 {
      &:nth-child(#{$i}) {
        animation-delay: $i * 0.05s;
      }
    }
    &:before, &:after {
      opacity: 0;
      animation: fadeIn 1s .5s ease forwards;
    }
    &:before {
      content: attr(class); 
    }
    &:after {
      float: right;
    }
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// TODO: Label text should know if it should be black or white based on the background color darkness.
// TODO: Lighter colors (#bee4e1) can return white values for A-level tints. Should be slightly smarter than this.



.page-container {
  padding: 24px;
  // background-color: rgba(8, 0, 112, 0.12);
  background-color: #FAFAFF;
  border-radius: 15px;

  -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.12);
}


