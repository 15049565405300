:root {

    --epg-rounded-status-chip-background-radius-size: 15px;

    --epg-rounded-status-chip-background-width: var(--epg-rounded-status-chip-background-radius-size);
    --epg-rounded-status-chip-background-height: var(--epg-rounded-status-chip-background-radius-size);

    --epg-rounded-status-chip-background-color:  var(--epg-primary-color-300);


}