@import '../color';

// Overrides styles for pux-customerbar component

.navbar {
  background-color: $secondary-color !important;
}

.pux-portal-bar-button-rounded {
  background-color: $primary-color;
}

.navbar-selected-button {
  //TODO: Replace with the correct variables names
  background-color: #3386BB !important;

  &:hover {

    i {
      color: #bfe1f5 !important;
    }

    .payroll-file-text-translation {
      color: #bfe1f5 !important;
    }

    .payroll-file-text {
      color: #bfe1f5 !important;
    }

    .payroll-file-name {
      color: #bfe1f5 !important;
    }

    .payroll-file-name-translation {
      color: #bfe1f5 !important;
    }
  }
}

.pux-portal-bar-button-rounded {

  &:visited,
  &:enabled:focus {
    color: #3386BB !important;
  }

}